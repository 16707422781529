.sliderContainer {
    display: block;
    height: 100px;
    // flex-direction: column
    // justify-content: center
    // align-items: center
    // width: 100%
    // min-height: 48px
    .sliderAndButton {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .slider_slide {
            width: 55%;
            min-width: 200px;
 }            // margin: 30px 0 30px 0
        .slider_button {
            height: 40px;
            width: 40px;
            background-color: white;
            color: black;
            border-radius: 5px;
            border-color: transparent;
            mix-blend-mode: difference;
            margin: 0 10px 0 10px !important;
            font-weight: 1000;
            font-size: 30px;
            z-index: 1;
            font-family: futura;
            user-select: none !important;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
                background-color: darken(white, 5%); }
            &:active {
                background-color: darken(white, 10%); } } }
    .slider_label {
        position: absolute;
        color: white;
        mix-blend-mode: difference;
        margin: -68px 50px 30px -80px !important;
        font-weight: 1000;
        font-size: 30px;
        z-index: 1;
        font-family: futura;
        pointer-events: none;
        &.creneau {
            margin: -60px 50px 30px -80px !important;
            font-size: 18px; } }
    .labelSliderCTA {
        position: absolute;
        margin: -64px 50px 30px -30px !important;
        font-size: 30px;
        font-family: futura;
        pointer-events: none;
        z-index: 1;
        @media screen and (max-width: 750px) {
            margin-top: -64px !important;
            margin-left: -25px !important; }
        &.ctabudget {
            margin-top: 52px; }
        .labelSliderCTA-animated {
            animation-name: animationslideside;
            animation-duration: 1.8s;
            animation-iteration-count: infinite;
            animation-direction: alternate; } } }

@keyframes animationslideside {
    from {
        margin-left: -140px; }
    to {
        margin-left: -170px; } }
