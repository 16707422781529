.tasteContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 80vw;
    @media screen and (max-width: 750px) {
        width: 100vw;
        height: 50vh; }
    .tasteCard {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        // background-color: #dd9999
        flex-wrap: wrap;
        width: 100%;
        min-width: 350px;
        // max-width: 600px
        height: 100%;
        // min-height: 500px
        // max-height: 600px
        // box-shadow: 5px 5px 43px -5px #00000080
        // border-radius: 15px
        transition: box-shadow 0.3s ease-in-out;
        // &:hover
        //  // box-shadow: 3px 3px 20px -2px #00000080
        .tasteColumn {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            width: 46%;
            max-width: 300px;
            height: 95%;
            border-radius: 15px;
            box-shadow: 1px 1px 10px -1px #00000080;
            background-color: #fff;
            transition: box-shadow 0.3s ease-in-out;
            &:hover {
                box-shadow: none; }
            &:hover > .tasteColumnDnd {
                box-shadow: none; }
            .tasteColumnTitre {
                color: black;
                font-family: futura;
                text-transform: lowercase;
                font-size: 1.5rem; }
            .tasteColumnDnd {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 2% 0px 2% 0px;
                // box-shadow: inset 1px 1px 10px -1px #00000080
                height: 80%;
                width: 95%;
                background-color: #F08F7C;
                border-radius: 15px;
                transition: box-shadow 0.3s ease-in-out;
                overflow-x: hidden;
                overflow-y: scroll;
                .tasteItem {
                    display: flex !important;
                    justify-content: space-between;
                    align-items: center;
                    font-family: futura;
                    width: 80%;
                    cursor: default;
                    border-radius: 5px;
                    margin: 5px 0 5px 0;
                    padding: 0px 20px 0px 20px;
                    // font-size: 15px
                    @media screen and (max-width: 750px) {
                        justify-content: space-around;
                        // font-size: 13px
                        padding: 0px;
                        width: 90%;
                        min-height: 30px; }
                    .tasteItemButton {
                        cursor: pointer;
                        width: 20px;
                        padding: 10px 10% 10px 10%;
                        user-select: none;
                        @media screen and (max-width: 750px) {
                            padding: 10px 3% 10px 3%; } } } }

            .tasteReset {
                cursor: pointer;
                height: 25px;
                width: 25px;
                margin: 5px;
                > svg {
                    height: 100%;
                    width: 100%; } } } } }
