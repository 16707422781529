.q_container {
    height: 100vh;
    // max-height: calc(var(--vhmobileheight, 1vh) * 100) !important
    overflow: hidden;
    background: #F08F7C;
    @media screen and (max-width: 750px) {
        max-height: calc(var(--vhmobileheight, 1vh) * 100) !important; } }

.q_page {
    background: linear-gradient(90deg, #F08F7C 100%, #F08F7C 100%);
    height: 100vh;
    overflow: hidden; }

.q_question {
    overflow: hidden;
    color: white;
    font-weight: 500;
    font-size: 25px;
    // margin-top: 10px
    // margin-bottom: 10px
    &.budget {
        margin-top: 0px; } }
.subquestion {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 20px;
    text-align: left; }
@media screen and (max-width: 750px) {
    .q_question {
        font-size: 17px;
        margin-top: 20px;
        margin-bottom: 20px;
 }        // overflow-y: scroll !important
    .subquestion {
        color: white;
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 20px;
        text-align: left; } }

.q_slide_container {
    height: calc(100vh - 70px);
    height: calc(var(--vhmobileheight, 1vh) * 100);
    // padding-top: 70px
    width: 100vw;
    max-width: 100vw;
    display: flex !important;
    justify-content: center !important;
    align-items: flex-start !important;
    overflow-y: auto !important;
    .q_slide {
        display: flex !important;
        flex-direction: column !important;
        flex-wrap: nowrap !important;
        justify-content: space-evenly !important;
        align-items: center !important;
        vertical-align: middle !important;
        width: 80% !important;
        min-height: calc(100% - 70px) !important;
        padding-top: 70px;
        // height: auto !important
        // padding-top: 70px
        // overflow-x: scroll !important
        .form-control-material {
            border-color: #cccccc;
            padding: 13px;
            font-size: 15px;
            height: 4%;
            width: 25% !important;
            border-width: 0px;
            border-radius: 10px;
            border-style: solid;
            box-shadow: 4px 1px 30px 0px rgba(42, 42, 42, 0.17);
            text-shadow: 0px 0px 0px rgba(42, 42, 42, 0.75);
            // user-select: none
            text-decoration: none;
            outline: none;
            @media screen and (max-width: 750px) {
                width: 55% !important; } }
        .form-address-material {
            border-color: #cccccc;
            font-size: 15px;
            height: 4%;
            margin-bottom: 3%;
            width: 80% !important;
            border-width: 0px;
            border-radius: 10px;
            border-style: solid;
            box-shadow: 4px 1px 30px 0px rgba(42, 42, 42, 0.17);
            text-shadow: 0px 0px 0px rgba(42, 42, 42, 0.75);
            text-decoration: none;
            outline: none;
            z-index: 999;
            // @media screen and (max-width: 750px)
            //     width: 60% !important
            >input::-webkit-input-placeholder {
                color: #000000 !important; }
            @media screen and (max-width: 750px) {
                width: 100% !important;
                font-size: 13px !important; } } } }

.recapVisible {
    opacity: 0.9;
    transition: all 1s; }

.floatingRecap {
    background-color: white;
    color: black;
    width: 100%;
    // height: 30vh
    margin-top: 80vh;
    z-index: 999;
    position: fixed;
    .floatingRecapContent {
        z-index: 999999999;
        text-align: left;
        color: black;
        font-family: futura;
        font-size: 15px;
        .recapChartjs {
            height: 10px;
            width: 5px;
            display: inline-block;
            padding: 3%; } } }
@media screen and (max-width: 750px) {
    .floatingRecap {
        width: 100%;
        height: 20%; } }

.q_button {
    // height: 100%
    text-decoration: none;
    font-size: 13.4px;
    outline: none;
    border: 2px solid black;
    background-color: transparent;
    transition: all 0.3s;
    border-radius: 4px;
    padding: 9px;
    color: black;
    font-family: helvetica;
    font-weight: 700;
    margin: 5%;
    align-items: top;
    @media screen and (max-width: 750px) {
        margin: 5% 0% 5% 0; }
    &:hover {
        background-color: black;
        color: white;
        cursor: pointer; }
    &:active {
        background-color: darken(white, 70);
        border: 2px solid darken(white, 70); }
    &.start {
        margin: 4px;
        width: 160px;
        .q_button_picto {
            font-size: 25px;
            margin: 10px; }
        .q_button_text {} }
    &.choice {
        margin-left: 10px;
        margin-top: 4%; }
    &.chosen {
        background-color: black;
        color: white;
        cursor: pointer; }
    &.prev {
        border: none;
        border-radius: 100%;
        margin: 10px 0px 10px 0px;
        background-color: black;
        color: white;
        &:hover {
            background-color: transparent;
            color: black;
            cursor: pointer; } }
    &.nbbottles {
        font-size: 20px;
        background-color: white;
        border: none;
        margin-top: 0px;
        &:hover {
            background-color: white !important;
            color: black !important;
            border: none;
            cursor: default; } }
    &.infos {
        &:hover {
            background-color: transparent !important;
            color: white !important;
            border: 2px solid white; } }
    &.invalid {
        &:hover {
            cursor: default;
            background-color: #F08F7C !important;
            color: white !important;
            border: 2px solid white; } } }

.bioButtons {
    display: flex;
    flex-direction: column; }

.slick-slide {
    outline: none;
    user-select: none;
    >div {
        outline: none;
        user-select: none;
        >div {
            outline: none;
            user-select: none; } } }

.budgetsTitre {
    margin-top: 2%;
    margin-bottom: 2%;
    font-family: futura;
    color: white;
    font-weight: bold;
    @media screen and (max-width: 750px) {
        font-size: 1.8vh; } }

.nbBouteilleRappel {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .inputSlider {
        width: 70%; }
    .disabledSliderBlock {
        cursor: default !important;
        display: block;
        border-radius: 5px;
        height: 30px;
        z-index: 0;
        min-width: 200px;
        max-width: 300px;
        width: 70%;
        background-color: #131313;
        box-shadow: 2px 2px 5px 2px rgba(42, 42, 42, 0.17);
        cursor: pointer;
        transition: all 1s;
        &.almost {
            background-color: white; }
        &.free {
            background-color: white; } }

    .labelDisabledSlider {
        color: white;
        margin-top: -28px;
        font-weight: 1000;
        font-size: 22px;
        z-index: 1;
        font-family: futura;
        pointer-events: none;
        transition: all 0.8s;
        &.almost {
            color: black; }
        &.free {
            color: #F08F7C;
            margin-top: -31px;
            font-size: 26px; }
        @media screen and (max-width: 750px) {
            font-size: 15px;
            margin-top: -25px;
            &.almost {}
            &.free {
                font-size: 24px; } } } }

.labelSliderText {
    color: white;
    mix-blend-mode: difference;
    margin-top: 7px;
    text-align: right;
    font-size: 20px;
    z-index: 1;
    font-family: futura;
    pointer-events: none;
    position: absolute;
    display: block;
    &.Left {
        margin-left: -60px;
        margin-top: 40px; }
    &.Right {
        margin-left: 210px;
        margin-top: 40px; } }
@media screen and (max-width: 750px) {
    .labelSliderText {
        font-size: 15px;
        margin-top: 10px;
        &.Left {
            margin-left: -40px !important; }
        &.Right {
            margin-left: 205px !important; } }
    // .rappel
    //     margin-top: 55px !important
 }    //     margin-left: 5px

.labelSlider {
    color: white;
    mix-blend-mode: difference;
    margin-top: -34px;
    font-weight: 1000;
    font-size: 30px;
    z-index: 1;
    font-family: futura;
    pointer-events: none; }

.labelSliderCTA {
    // margin-left: -153px
    font-size: 30px;
    font-family: futura;
    pointer-events: none;
    z-index: 1;
    @media screen and (max-width: 750px) {
        margin-top: -38px; }
    &.ctabudget {
        margin-top: 52px; }
    .labelSliderCTA-animated {
        animation-name: animationslideside;
        animation-duration: 1.8s;
        animation-iteration-count: infinite;
        animation-direction: alternate; } }

@keyframes animationslideside {
    from {
        margin-left: -140px; }
    to {
        margin-left: -170px; } }

.inputSlider {
    &:hover {
        box-shadow: none;
        transition: all 0.3s; } }

.q_bottle_img {
    position: absolute;
    height: 200px;
    z-index: 0;
    transition: opacity 1s ease-in;
    opacity: 1;
    z-index: -9;
    margin-top: 120vh;
    &.giftbottle {
        margin-top: 26vh !important; }
    &.bottle_1 {
        top: 100vh;
        left: 8%; }
    &.bottle_2 {
        top: 110vh;
        left: 16%; }
    &.bottle_3 {
        top: 120vh;
        left: 24%; }
    &.bottle_4 {
        top: 115vh;
        left: 32%; }
    &.bottle_5 {
        top: 115vh;
        left: 63%; }
    &.bottle_6 {
        top: 120vh;
        left: 71%; }
    &.bottle_7 {
        top: 110vh;
        left: 79%; }
    &.bottle_8 {
        top: 100vh;
        left: 87%; } }
@media screen and (max-width: 750px) {
    .q_bottle_img {
        height: 100px;
        margin-top: 100vh;
        margin-top: calc(var(--vhmobileheight, 1vh) * 130);
        &.giftbottle {
            margin-top: -20vh !important; }
        &.bottle_1 {
            top: 120vh;
            left: 8%; }
        &.bottle_2 {
            top: 120vh;
            left: 16%; }
        &.bottle_3 {
            top: 120vh;
            left: 24%; }
        &.bottle_4 {
            top: 120vh;
            left: 32%; }
        &.bottle_5 {
            top: 120vh;
            left: 63%; }
        &.bottle_6 {
            top: 120vh;
            left: 71%; }
        &.bottle_7 {
            top: 120vh;
            left: 79%; }
        &.bottle_8 {
            top: 120vh;
            left: 87%; } } }

.hidden {
    transition: opacity 1s ease-out;
    opacity: 0; }

// Prop setter
.propsetter {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    flex-wrap: wrap;
    .propsetterChartjs {
        // background-color: rgb(255, 255, 255)
        height: 100%;
        width: 40%;
        padding: 3%;
        > div {
            height: 100%; } }
    .propsetterControler {
        // background-color: grey
        // height: 100%
        // min-height: 500px
        width: 40%;
        padding: 3%;
        // padding-top: 6%
        display: inline-block;
        overflow: visible; }
    .propsetter-footer {
        width: 100%; } }
.propsetterContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 120px;
    .propsetterChartjsRecap {
        // background-color: rgb(255, 255, 255)
        height: 50%;
        width: 50%;
        display: inline-block;
        padding: 3%;
        .chartjs-render-monitor {
            height: 50%;
            width: 50%; } }
    .propsetterTitreRecap {
        font-size: 14px;
        font-weight: 500; } }
.propsetterLine {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &.regions {
        margin-bottom: 5px; } }
@media screen and (max-width: 750px) {
    .propsetter {
        flex-direction: column;
        // height: fit-content !important
        .propsetterChartjs {
            height: 30% !important;
            width: 80% !important;
            // display: inline-block
            padding: 3%; }
        .propsetterControler {
            // background-color: grey
            width: 80% !important;
            // height: 50% !important
 }            // display: inline-block
        .propsetter-footer {
            width: 80% !important;
            height: 20% !important; } } }
.propsetterCard {
    display: inline-block;
    background-color: white;
    border-radius: 5px;
    margin: 0px 10px 0px 10px;
    height: 30px;
    max-width: 300px;
    min-width: 300px;
    overflow: hidden;
    transition: all 0.3s;
    box-shadow: 2px 2px 5px 2px rgba(42, 42, 42, 0.17);
    cursor: pointer;
    &:hover {
        box-shadow: none;
        transition: all 0.3s !important; }
    &.opened {
        transition: all 0.3s;
        height: 145px; }
    .cardHeader {
        width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        .cardPastille {
            position: relative;
            border-radius: 50%;
            margin-left: 6px;
            // margin-top: 6px
            height: 18px;
            width: 18px;
            &.rouge {
                background-color: #F50208; }
            &.blanc {
                background-color: #0773A1; }
            &.rosé {
                background-color: #F08F7C; }
            &.champagne {
                background-color: #F8DBA1; }
            &.HDG {
                background-color: #ffe888; }
            &.MDG {
                background-color: #DCDCDC; }
            &.EDG {
                background-color: #d2be93; } }
        .cardTitre {
            font-family: helvetica;
            font-size: 13px;
            font-weight: 400;
            margin-left: 10px; }
        .cardArrow {
            border-radius: 50%;
            transition: all 0.3s;
            transform: rotate(-180deg);
            margin-top: -4px;
            margin-left: 8px;
            &.rotate {
                transform: rotate(0deg);
                margin-top: 4px;
                transition: all 0.3s; } } }
    .cardContent {
        width: 100%;
        .cardContentImg {}
        .cardContentText {
            font-family: helvetica;
            font-size: 14px;
            padding: 4%;
            text-align: left;
            > i {
                display: block;
                margin-bottom: 5px; } } } }
@media screen and (max-width: 750px) {
    .propsetterCard {
        display: inline-block;
        background-color: white;
        border-radius: 5px;
        height: 30px;
        max-width: 200px;
        min-width: 200px;
        &.opened {
            height: 170px; } }
    .cardContent {
        width: 100%;
        .cardContentImg {}
        .cardContentText {
            padding-top: 0% !important; } } }

[id^="cbx"] {
            display: none;
            &:checked + .cbx {
                &.blanc {
                    border-color: #0773A1; }
                &.rouge {
                    border-color: #F50208; }
                .flip {
                    transform: rotateY(180deg); } } }

.cbx {
    -webkit-perspective: 20;
    perspective: 20;
    // position: absolute
    top: 50%;
    left: 50%;
    margin: 10px 20px 10px 20px;
    border: 2px solid #E8E8EB;
    background: #E8E8EB;
    border-radius: 4px;
    transform: translate3d(0,0,0);
    cursor: pointer;
    transition: all .3s ease;
    @media screen and (max-width: 750px) {
        margin: 10px 5px 10px 5px; }
    &:hover {
        border-color: #000000; }
    &.disabled {
        border: 2px solid #ffffff;
        cursor: default;
        .flip {
            .front {
                background: #F08F7C !important; } } }
    .flip {
        display: block;
        transition: all .4s ease;
        transform-style: preserve-3d;
        position: relative;
        width: 20px;
        height: 20px;
        .front,
        .back {
            backface-visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border-radius: 2px; }
        .front {
            transition: background 0.4s;
            background: white;
            z-index: 1; }
        .back {
            transform: rotateY(180deg);
            text-align: center;
            color: white;
            line-height: 20px;
            box-shadow: 0 0 0 0px #000000;
            &.checkBlanc {
                background: #0773A1; }
            &.checkRouge {
                background: #F50208; }
            svg {
                margin-top: 3px;
                fill: none;
                path {
                    stroke: white;
                    stroke-width: 2.5;
                    stroke-linecap: round;
                    stroke-linejoin: round; } } } } }

.propsetterButtonContainer {
    line-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 30px;
    .propsetterButton {
        background-color: white;
        margin-left: 10px;
        margin-right: 10px;
        font-family: arial;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: none;
        box-shadow: 2px 2px 5px 2px rgba(42, 42, 42, 0.17);
        transition: box-shadow 0.3s;
        &:hover {
            box-shadow: none;
            transition: box-shadow 0.3s; }
        #checkboxMore:checked + label:before {
            content: "\f0aa"; } } }

@media screen and (max-width: 750px) {
    .propsetterButtonContainer {
        .propsetterButton {
            text-align: center; } } }

.regionPickers {
    margin-top: 15px;
    // max-width: 55%
    .checkboxRegion {
        margin-left: 10px;
        margin-right: 10px;
        font-family: arial;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-shadow: 2px 2px 5px 2px rgba(42, 42, 42, 0.17);
        transition: box-shadow 0.3s;
        border: 0px;
        &:hover {
            box-shadow: none;
            transition: box-shadow 0.3s; } } }

.regionPickersContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .regionsTitreContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @media screen and (max-width: 750px) {
            max-width: 340px; }
        .regionsTitreBloc {
            width: 25%;
            max-width: 120px;
            @media screen and (max-width: 750px) {
                width: 110px; }
            .regionsTitreBlocHead {}

            .regionsTitreBody {
                display: flex;
                justify-content: space-between; }
            .regionsTitre {
                font-family: futura;
                color: white;
                font-weight: bold;
                &.line1 {
                    letter-spacing: 3px; } } } } }

.contactButtonsContainer {
    width: 190px !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    > button {
        width: 165px; } }

.smallPopup {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
    .smallPopupInner {
        > div {
            margin: 7%;
            font-size: 18px; }
        .smallPopupTitre {
            font-size: 20px;
            font-weight: bold; }
        .smallPopupTexte {}
        .smallPopupEnd {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-style: italic; } } }

.sentPopupStepsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 170px;
    .stepContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: nowrap;
        width: 15%;
        height: 100px;
        .stepImage {
            height: 100px; }
        .stepTexte {
            font-size: 15px;
            white-space: nowrap;
            margin-left: -100%;
            margin-right: -100%;
            text-align: center; } }
    .stepArrowContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 5%;
        height: 80px; } }

.confirmationPopup {
    overflow-y: scroll;
    .recap {
        padding-left: 5%;
        padding-right: 5%;
        display: flex;
        flex-direction: column;
        justify-content: safe center;
        .recapCategorie {
            margin-bottom: 0px;
            // border: 1px solid black
            // border-radius: 5px
            padding: 5px;
            > div {
                margin-bottom: 3px; }
            .recapCategorieTitre {
                font-family: futura;
                font-weight: bold;
                font-size: 12px; } } } }

.recapHeader {
    display: flex;
    flex-direction: column;
    justify-content: safe center;
    padding: 7%;
    .titre {
        font-size: 20px;
        font-weight: bold;
        @media screen and (max-width: 750px) {
            font-size: 13px; } }
    .sousTitre {
        font-size: 15px;
        font-weight: bold;
        font-style: italic;
        @media screen and (max-width: 750px) {
            font-size: 12px; } } }
.recapContent {
    display: flex;
    justify-content: space-evenly;
    text-align: left;
    .recapLeft {
        min-width: 50%; }
    .recapRight {
        min-width: 50%; } }
.recapBottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3%;
    > button {
        margin: 0px;
 }        // margin-top: 10px
    .popupRecapLoadingContainer {
        height: 50px; } }

.slick-dots {
    display: none !important; }
