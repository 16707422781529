// .accueilSection5
//     background-color: #181818
//     margin-top: -15vh
//     padding-top: 20vh
//     height: 110vh
//     clip-path: polygon(66% 10%, 100% 6%, 100% 100%, 0 100%, 0 0, 32% 3%)
.accueilSection5backgroundimage {
    position: absolute;
    z-index: -1;
    display: block;
    background-image: url("../img/pic-restaurant.jpg");
    background-size: cover;
    width: 100vw;
    min-height: 110%; }
.section5Titre {
    // margin-top: 15vh
    font-family: helvetica;
    font-weight: 800;
    font-size: 35px;
    color: white;
    // margin-top: 6vh
    // margin-bottom: 6vh
    text-transform: uppercase;
 }    // transform: rotate(8deg)
.accueilSection5Slide {
    display: flex;
    justify-content: space-around;
    width: 100vw;
    // height: 450px
    transition: max-height 0.3s;
    @media screen and ( max-width: 750px ) {
        height: 390px; }
    .avisContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 1% 0% 1% 0%;
        background-color: white;
        // height: 100%
        width: 80%;
        max-width: 730px;
        border-radius: 8px;
        .avisHeader {
            display: flex;
            justify-content: space-around;
            width: 90%;
            padding: 0% 3% 0% 3%;
            .avisHeaderTitre {
                font-family: helvetica;
                font-weight: 500; }
            .headerPerson {
                cursor: pointer;
                .inactive {
                    filter: grayscale(100%) brightness(0.4) !important;
                    transition: filter 0.3s; }
                .pic {
                    filter: grayscale(0%);
                    transition: filter 0.3s;
                    max-width: 128px;
                    clip-path: circle(40%); }
                .nom {
                    font-weight: 300; }
                .age {
                    font-weight: 300; } } }
        .avisContent {
            .contentPerson {
                padding: 5% 5% 0% 5%;
                display: block;
                opacity: 1 !important;
                transition: opacity 0.5s, display 1s;
                text-align: left;
                .date {
                    font-style: italic;
                    font-weight: 400;
                    margin-bottom: 10px; }
                .texte {
                    font-weight: 300;
                    line-height: 22px; } }
            .cache {
                display: none !important;
                opacity: 0 !important;
                transition: opacity 0.5s, display 1s; } }
        .avisFooter {
            display: flex;
            justify-content: center;
            width: 60%;
            height: auto; } } }
@media screen and ( max-width: 750px ) {
    .pic {
        max-width: 70px !important; }
    .contentPerson {
        .date {
            font-size: 13px; }
        .texte {
            font-size: 13px;
            line-height: 10px !important; } } }
