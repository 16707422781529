.faqContainer {
  width: 100vw;
  overflow: hidden;
  .titreContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100vw;
    height: 350px;
    .titreFaq {
      margin-top: 180px;
      padding-bottom: 2%;
      font-family: Helvetica;
      font-weight: 500;
      font-size: 35px;
      z-index: 999;
      color: white;
      @media screen and ( max-width: 800px  ) {
        font-size: 35px !important; } }
    .soustitreFaq {
      font-family: Helvetica;
      font-size: 40px;
      font-style: italic;
      z-index: 999;
      color: white;
      padding-left: 10%;
      padding-right: 10%;
      @media screen and ( max-width: 800px  ) {
        font-size: 30px !important; } } }
  .ParallaxContainer {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px !important;
    filter: brightness(0.5);
    @media screen and ( max-width: 800px  ) {
      background-attachment: local; } }
  .faqContent {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-evenly;
    @media screen and ( max-width: 800px  ) {
      flex-wrap: wrap;
      flex-direction: column-reverse; }
    > div {
      margin-left: 5%;
      margin-right: 5%; }
    .faqTitle {
      font-size: 20px;
      font-family: Helvetica;
      font-weight: 500;
      text-align: left;
      letter-spacing: 4px;
      margin-bottom: 30px; }
    .faqCGV {
      text-align: justify;
      width: 50%;
      @media screen and ( max-width: 800px  ) {
        width: 85% !important; }
      .cgvBloc {
        margin-bottom: 70px; } }
    .faqQuestions {
      width: 50%;
      @media screen and ( max-width: 800px  ) {
        width: 85% !important;
        margin-bottom: 70px; } }

    .questioncell {
      margin-bottom: 1px;
      overflow: hidden;
      max-height: 70px;
      transition: max-height 0.7s ease-out;
      cursor: pointer;
      &.opened {
        max-height: 1000px;
        transition: max-height 0.7s ease-in;
        cursor: pointer; }
      .questionTitreContainer {
        display: flex;
        align-items: center;
        height: 70px;
        width: 100%;
        .questionTitre {
          text-align: left;
          font-weight: 600;
          letter-spacing: 1px;
          font-size: 1rem;
          @media screen and (max-width: 600px) {
            letter-spacing: 0px; } } }
      .questionReponse {
        text-align: left; } } } }
//DESKTOP
// @media screen and ( min-width: 940px )

//MOBILE
// @media screen and ( max-width: 940px )
