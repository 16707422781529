.cardContainer {
  height: fit-content !important;
  background-color: white;
  padding: 25px;
  padding-top: 0px;
  padding-bottom: 10px;
  border-radius: 8px;
  -webkit-transition: box-shadow .4s ease-out;
  box-shadow: 1px 8px 15px #00000085;
  pointer-events: none;
  .cardLeftPart {
    height: 100%;
    width: 50%;
    .cardLeftPartImage {
      height: 100%;
      width: 100%; } }
  .cardRightPart {
    height: 100%;
    width: 50%;
    .cardTextBlocTitre {
      font-family: futura;
      font-size: 22px; }
    .cardTextBlocTexte {
      font-family: Helvetica;
      font-size: 17px; } }
  .cardPince {
    margin-top: -53px; }
  .cardTopPart {
    height: 50%;
    width: 80%;
    display: inline-block;
    .cardImage {
      margin-top: 10%;
      margin-bottom: 10%;
      // height: 100%
      width: 100%; } }
  .cardLeftPart {
    height: 50%;
    width: 80%;
    display: inline-block;
    .cardImage {
      margin-top: 10%;
      // height: 100%
      width: 100%; } }
  .cardTextOnly {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .cardTextBlocTitre {
      font-family: futura;
      min-width: 280px;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      text-justify: inter-ideograph;
      // padding: 14%
      padding-top: 3%;
      padding-bottom: 7%;
      margin: 0px; }
    .cardTextBlocTexte {
      color: black;
      font-family: Helvetica;
      font-weight: 450;
      font-size: 17px;
      text-align: justify;
      padding: 10%;
      padding-top: 0%;
      padding-bottom: 4%;
      margin: 0px; }
    .buttonsContainer {
      .buttonContainer {
        >button {
          pointer-events: all;
          display: flex;
          justify-content: center;
          width: 100% !important; } } } } }
@media screen and ( max-width: 750px ) {
  .cardPince {
    margin-top: -79px !important; }
  .cardContainer {
    width: 60vw !important;
    padding: 25px; }
  .cardTopPart {
    height: 30% !important;
    width: 90% !important;
    .cardImage {
      margin-top: 10% !important; } }
  .cardTextBlocTitre {
    min-width: 200px !important;
    font-size: 16px !important;
    padding: 5% !important;
    margin: 0% 5% 0% 5%; }
  .cardTextBlocTexte {
    font-size: 14px !important;
    padding: 5% !important; } }
