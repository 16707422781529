//SLIDER & BOTTLES
.livraisonCreneauContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }

.livraisonCreneauBloc {
    transform: scale(0.8);
    display: flex;
    justify-content: center;
    .input-creneau {
        background-color: white;
        border-radius: 5px; } }
.CreneauSumup {
    color: red; }
.DayPicker-Day--selected {
    background-color: #F08F7C !important; }
.DayPicker-Day {
    outline: none; }
