// .accueilSection3
//     // margin-top: -15vh
//     padding-top: 20vh
//     height: 120vh
//     min-height: 980px
//     background-color: black
//     // background: linear-gradient(90deg, #ffb6b6 100%, rgb(255, 157, 157) 100%)
//     clip-path: polygon(66% 10%, 100% 6%, 100% 100%, 0 100%, 0 0, 32% 3%)
//     @media screen and ( max-width: 940px )
//         padding-top: 10vh
.accueilSection3backgroundimage {
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
    display: block;
    background-image: url("../img/pic-diner.jpg");
    background-size: cover;
    width: 100vw;
    min-height: 110%;
    transform: scale(1.1);
    overflow: hidden;
    @media screen and ( max-width: 750px ) {
        margin-top: -10%;
        min-height: 150%; } }
.cardSection3 {
    display: flex;
    background-color: white;
    width: 400px;
    height: 20vh;
    border-radius: 8px;
    // min-height: 170px
    margin: 3%;
    margin-left: 25%;
    .leftContainer {
        display: flex;
        justify-content: space-evenly;
        width: 30%;
        margin: 5%;
        align-content: center;
        .leftImg {
            height: 100%;
            width: auto; } }
    .rightContainer {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        text-align: left;
        padding: 5%;
        padding-left: 0%;
        .titre {
            font-family: futura;
            font-weight: bold;
            font-size: 16px; }
        .texte {
            font-family: futura;
            font-size: 14px; }
        .prix {
            font-family: futura;
            font-weight: 300;
            font-style: italic;
            font-size: 15px; } } }

.imgRightSection3 {
    // position: absolute
    margin-top: -650px;
    margin-left: 81vw;
    .rightImg {
        height: 600px;
        width: auto;
        animation: scale 1s cubic-bezier(0.45, 0.05, 0.55, 0.95) !important; } }

.personnaliseSection3 {
    margin-top: -1300px;
    margin-left: 45vw;
    .personnaliseImg {
        height: 150px;
        width: auto; } }

@-moz-keyframes spin {
    0% {}
    -moz-transform: rotate(0deg);
    60% {}
    -moz-transform: rotate(100deg);
    100% {}
    -moz-transform: rotate(0deg); }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  60% {
    -webkit-transform: rotate(100deg); }
  100% {
    -webkit-transform: rotate(0deg); } }

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
    60% {
        -webkit-transform: rotate(100deg);
        transform: rotate(100deg); }
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); } }

@media screen and ( max-width: 940px ) {
    .cardSection3 {
        display: flex;
        background-color: white;
        width: 280px;
        min-height: 110px !important;
        margin: 6%;
        margin-left: 32%;
        .rightContainer {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            text-align: left;
            padding: 5%;
            padding-left: 0%;
            .titre {
                font-size: 14px !important; }
            .texte {
                font-size: 12px !important;
                line-height: 12px; }
            .prix {
                font-size: 12px !important; }
            .section3Titre {
                // font-family: Helvetica
                // font-weight: 800
                // font-size: 35px
                // color: white
 } } }                // margin-top: -12vh
    .imgRightSection3 {
        margin-top: -600px !important;
        .rightImg {
            height: 600px;
            width: auto;
            animation: scale 1s cubic-bezier(0.45, 0.05, 0.55, 0.95) !important; } } }
