.blogContainer {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  .titreContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100vw;
    height: 350px;
    .titreBlog {
      margin-top: 180px;
      margin-bottom: 0px;
      font-family: futura;
      font-weight: 900;
      font-size: 100px;
      z-index: 999;
      color: white;
      letter-spacing: 10px;
      @media screen and ( max-width: 800px  ) {
        font-size: 80px !important; } }
    .soustitreBlog {
      font-family: Helvetica;
      font-size: 40px;
      font-style: italic;
      z-index: 999;
      color: white;
      margin: 0px;
      letter-spacing: 5px;
      @media screen and ( max-width: 800px  ) {
        font-size: 30px !important; } } }
  .ParallaxContainer {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px !important;
    filter: brightness(0.5);
    @media screen and ( max-width: 800px  ) {
      background-attachment: local; } }
  .blogFilters {
    background-color: white;
    display: flex;
    justify-content: center;
    .blogCategoriesFilter {
      margin-top: 20px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      width: 80%;
      height: fit-content;
      max-width: 650px;
      .blogCategorieButton {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 100px;
        border: 2px solid black;
        background-color: white;
        margin: 2%;
        transition: all 0.3s;
        border-radius: 4px;
        font-family: Helvetica;
        font-weight: 700;
        font-size: 12px;
        color: black;
        letter-spacing: 2px;
        &:hover {
          cursor: pointer !important;
          transform: scale(1.1) !important; }
        &.selected {
          background-color: black !important;
          color: white; } } } }

  .blogContent {
    position: relative;
    background-color: white;
    display: flex;
    justify-content: center;
    .blogFeatured {}
    .blogArticles {
      max-width: 700px;
      width: 80%;
      // background-color: green
      .articleBloc {
        margin: 30px;
        margin-top: 0px;
        border-radius: 5px;
        // transition: all 0.3s
        // &:hover
        //   transition: all 0.3s
        //   -webkit-box-shadow: .8px 9px 23px  rgba(0,0,0,0.25)
        //   -moz-box-shadow: .8px 9px 23px  rgba(0,0,0,0.25)
        //   box-shadow: .8px 9px 23px  rgba(0,0,0,0.25)
        // background-color: red
        .titre {
          padding-top: 15px;
          // padding-bottom: 0px
          font-family: futura;
          font-size: 26px;
          text-align: left;
          color: black;
          @media screen and ( max-width: 800px  ) {
            font-size: 20px !important; } }
        .subtitre {
          display: flex;
          flex-direction: row;
          .date {
            padding: 10px;
            font-family: futura;
            font-size: 15px;
            font-style: italic;
            text-align: left;
            color: #757575;
            @media screen and ( max-width: 800px  ) {
              font-size: 12px !important; } }
          .categoriesContainer {
            display: flex;
            flex-direction: row;
            .categorie {
              padding: 10px;
              padding-right: 5px;
              padding-left: 5px;
              font-family: futura;
              font-size: 15px;
              font-style: italic;
              text-align: left;
              color: #757575;
              cursor: pointer;
              &.colorSavoir {
                color: #F08F7C; }
              &.colorChiffres {
                color: #012875; }
              &.colorVignerons {
                color: #F8DBA1; }
              &.colorAnecdotes {
                color: #0773A1; }
              &.colorCaves {
                color: #F50208; } } } }
        .imageContainer {
          overflow: hidden;
          width: 100%;
          max-height: 400px;
          border-radius: 5px;
          .image {
            width: 100%;
            transition: transform 0.4s ease-in-out;
            &:hover {
              transform: scale(1.4);
              transition: transform 1.5s ease-in-out; } } }

        .apercu {
          padding-top: 10px;
          font-family: futura;
          font-size: 15px;
          text-align: left;
          color: #000000; } } } } }

.blogTitre {
  font-family: Helvetica;
  font-weight: 800;
  font-size: 30px;
  color: white;
  margin-top: -9vh;
  margin-bottom: 17vh;
  transform: rotate(8deg); }

.slick-dots li.slick-active button:before {
  color: white; }

//DESKTOP
// @media screen and ( min-width: 940px )

//MOBILE
// @media screen and ( max-width: 940px )
