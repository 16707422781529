.container {
  display: block;
  margin: 0 auto !important;
  /* makes it centered */
  max-width: 580px;
  width: 580px;
}

/* This should also be a block element, so that it will fill 100% of the .container */
.content {
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  max-width: 580px;
}

/* -------------------------------------
          HEADER, FOOTER, MAIN
      ------------------------------------- */
.main {
  background: #ffffff;
  border-radius: 13px;
  width: 100%;
  box-shadow: 5px 5px 43px -5px #00000080;
}

.wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  border-radius: 13px;
  box-sizing: border-box;
  background-color: white;
}

.content-block {
  padding-bottom: 10px;
  padding-top: 10px;
}

.leftPannel {
  height: 260px;
  width: 50%;
  padding-left: 0px;
  padding-top: 20px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.giftLogoContainer {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}
.giftLogo {
  width: 200px;
  height: 200px;
}
.leftPannelFooter {
  /* padding-left: 20px; */
  transform: rotate(5deg);
  color: #f08f7c;
  font-size: 25px;
}
.leftPannelFooterLine2 {
  font-size: 14px;
}

.rightPannel {
  width: calc(50% - 20px);
  padding-right: 20px;
  padding-top: 20px;
}
.rightPannelTitle {
  color: #000000;
  font-family: Futura;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
  margin-bottom: 30px;
}
.rightPannelMessage {
  font-family: sans-serif;
}
.giftInput {
  margin: 25px 0px 15px;
  padding: 12px;
  letter-spacing: 2px;
  text-align: center;
  font-size: 14px;
  /* border: 1px solid black; */
  border-radius: 5px;
  box-shadow: inset 2px 3px 6px 0px #0000003a;
}
.giftCode {
  letter-spacing: 15px !important;
  font-weight: bold;
}

.giftPrice {
  position: absolute;
  margin-top: -40px;
  margin-left: 30px;
  color: white;
  font-size: 22px;
}

.cardFooter {
  text-align: left;
  width: 100%;
  height: 50px;
  clip-path: polygon(44% 34%, 81% 0, 100% 12%, 100% 100%, 0 100%, 0 0);
  background-color: #f08f7c;
  border-bottom-left-radius: 13px !important;
  border-bottom-right-radius: 13px !important;
  padding-top: 20px;
  padding-left: 20px;
  color: white;
  font-size: 24px;
}

.footer {
  clear: both;
  margin-top: 10px;
  text-align: center;
  width: 100%;
}

.footer a {
  color: rgb(0, 0, 0);
  font-size: 12px;
  text-align: center;
  text-decoration: none;
}

/* -------------------------------------
          BUTTONS
      ------------------------------------- */
.btn {
  box-sizing: border-box;
  width: 100%;
}

.btn > tbody > tr > td {
  padding-bottom: 15px;
}

.btn table {
  width: auto;
}

.btn table td {
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;
}

.btn a {
  background-color: #ffffff;
  border: solid 1px #3498db;
  border-radius: 5px;
  box-sizing: border-box;
  color: #3498db;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 12px 25px;
  text-decoration: none;
  text-transform: capitalize;
}

.btn-primary table td {
  background-color: #3498db;
}

.btn-primary a {
  background-color: #3498db;
  border-color: #3498db;
  color: #ffffff;
}

.preheader {
  color: transparent;
  display: none;
  height: 0;
  max-height: 0;
  max-width: 0;
  opacity: 0;
  overflow: hidden;
  mso-hide: all;
  visibility: hidden;
  width: 0;
}

hr {
  border: 0;
  border-bottom: 1px solid #f6f6f6;
  margin: 20px 0;
}

/* -------------------------------------
          RESPONSIVE AND MOBILE FRIENDLY STYLES
      ------------------------------------- */
@media only screen and (max-width: 620px) {
  table[class="body"] h1 {
    font-size: 28px !important;
    margin-bottom: 10px !important;
  }

  table[class="body"] p,
  table[class="body"] ul,
  table[class="body"] ol,
  table[class="body"] td,
  table[class="body"] span,
  table[class="body"] a {
    font-size: 16px !important;
  }

  table[class="body"] .wrapper,
  table[class="body"] .article {
    padding: 10px !important;
  }

  table[class="body"] .content {
    padding: 0 !important;
  }

  table[class="body"] .container {
    padding: 0 !important;
    width: 100% !important;
  }

  table[class="body"] .main {
    border-left-width: 0 !important;
    border-radius: 13px !important;
    border-right-width: 0 !important;
  }

  table[class="body"] .btn table {
    width: 100% !important;
  }

  table[class="body"] .btn a {
    width: 100% !important;
  }

  table[class="body"] .img-responsive {
    height: auto !important;
    max-width: 100% !important;
    width: auto !important;
  }
}

/* -------------------------------------
          PRESERVE THESE STYLES IN THE HEAD
      ------------------------------------- */
@media all {
  .ExternalClass {
    width: 100%;
  }

  .ExternalClass,
  .ExternalClass p,
  .ExternalClass span,
  .ExternalClass font,
  .ExternalClass td,
  .ExternalClass div {
    line-height: 100%;
  }

  .apple-link a {
    color: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    text-decoration: none !important;
  }

  #MessageViewBody a {
    color: inherit;
    text-decoration: none;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  .btn-primary table td:hover {
    background-color: #34495e !important;
  }

  .btn-primary a:hover {
    background-color: #34495e !important;
    border-color: #34495e !important;
  }
}
