.accueilSection1backgroundimage {
  position: absolute;
  z-index: -1;
  display: block;
  background-image: url("../img/pic-bottles-2.jpg");
  background-size: cover;
  transform: scale(1.2);
  width: 100vw;
  min-height: 100vh; }
.accueilSection1Verre {
  position: absolute;
  z-index: 0;
  width: 30vh;
  height: auto;
  margin-left: -35vw;
  @media screen and ( max-width: 750px ) {
    z-index: -1; } }
.accueilSection1Card {
  display: grid;
  place-items: center;
  z-index: 9999999 !important; }
.accueilSection1Human {
  position: absolute;
  width: 400px;
  height: auto;
  margin-top: -10vh;
  margin-left: 33vw;
  transform: rotate(-8deg);
  z-index: 1 !important;
  @media screen and ( max-width: 750px ) {
    display: none; } }
.accueilSection1Drop {
  position: absolute;
  width: 500px;
  height: auto;
  margin-top: 20vh;
  margin-left: 35vw;
  // z-index: -999999 !important
  @media screen and ( max-width: 750px ) {
    display: none; } }
.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  .buttonContainer {
    min-width: 140px;
    width: 45%;
    margin-top: 3%;
    margin-bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and ( max-width: 750px ) {
      width: 100% !important; }
    &.Details {
      margin-top: 10%;
      .a_button {
        border: 2px solid white;
        background-color: black;
        color: white;
        &:hover {
          border: 2px solid black;
          background-color: black;
          color: white; } } } } }
.arrow_more {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transform: scale(2.2);
  color: white;
  animation-name: updownloop;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  > svg {
    &:hover {
      cursor: pointer; } } }
@keyframes updownloop {
  from {
    margin-top: 70vh; }
  to {
    margin-top: 78vh; } }
