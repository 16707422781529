.section4Titre {
  font-family: Helvetica;
  font-weight: 800;
  font-size: 30px;
  color: white; }
.section4cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: safe center;
  width: 70%;
  margin-left: 15%; }

@media screen and ( max-width: 940px ) {
  .accueilSection4slide {
    margin-top: -20vh;
    padding-top: 20vh;
    padding-bottom: 20vh !important;
    .section4cards {
      max-width: 70%;
      flex-direction: column;
      margin-left: 15%; } } }
