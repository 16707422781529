.paiementContainer {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vhmobileheight, 1vh) * 100);
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #F08F7C;
  .resumeContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    height: 80%;
    min-height: 600px;
    min-width: 500px;
    max-width: 600px;
    padding: 4vh;
    background-color: rgb(252, 252, 252);
    font-family: futura;
    font-size: 18px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 5px 5px 43px -5px #00000080;
    border-radius: 5px;
    @media screen and (max-width: 750px) {
      min-height: 500px;
      max-height: 550px;
      min-width: 350px; }
    &.creneauInvalid {
      justify-content: center;
      min-height: 670px;
      max-height: 1000px !important;
      @media screen and (max-width: 750px) {
        max-height: 1000px !important; } }
    &.loadingData {
      justify-content: center; }
    &.paiementeffectue {
      justify-content: center; }
    .paiementHeaderBloc {
      width: 100%;
      // height: 20%
      // margin: 15% 0% 5% 0%
      @media screen and (max-width: 750px) {
        // margin: 5% 0% 5% 0%
 }        // height: 15%
      .headerBloclogo {
        min-height: 45px;
        height: 70%;
        max-height: 100px;
        margin-top: 10%; }
      .headerBlocText {
        height: 30%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: default;
        user-select: none;
        .blocTextNom {}
        .blocTextPrix {
          background-color: black;
          width: fit-content;
          color: white;
          border-radius: 5px;
          padding: 5px;
          margin-top: 10%;
          @media screen and (max-width: 750px) {
            margin-top: 5%; } } } }
    .paiementCreneauBloc {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
 }      // transform: scale(0.8)
    .cadeauCardBloc {
      transform: scale(0.8);
      height: auto;
      // margin: -45% 0% 5% 0%
      @media screen and (max-width: 750px) {
        transform: scale(0.55);
 }        // margin: 5% 0% 5% 0%
      .headerBloclogo {
        height: 70%; }
      .headerBlocText {
        height: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: default;
        user-select: none;
        .blocTextNom {}
        .blocTextPrix {
          background-color: black;
          width: fit-content;
          color: white;
          border-radius: 5px;
          padding: 5px;
          margin-top: 10%;
          @media screen and (max-width: 750px) {
            margin-top: 5%; } } } }
    .paiementFormBloc {
      display: block;
      min-width: 80%;
      max-width: 300px !important;
      // height: 40px
      .cardElementContainer {
        height: 80px;
        display: inline;
        margin-bottom: 200px; } }
    .paymentImg {
      width: 90%;
      max-width: 300px; }
    .paiementOk {
      margin: 0% 8% 0% 8%; } } }
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  &--focus {
    box-shadow: 0 1px 3px 0 #cfd7df; }
  &--invalid {
    border-color: #fa755a; }
  &--webkit-autofill {
    background-color: #fefde5 !important; } }

//DESKTOP
// @media screen and ( min-width: 940px )

//MOBILE
// @media screen and ( max-width: 940px )
