.blogArticleContainer {
  width: 100vw;
  overflow: hidden;
  .titreContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100vw;
    height: 350px;
    .titreBlog {
      margin-top: 180px;
      margin-bottom: 0px;
      padding: 0px 15% 0px 15%;
      font-family: futura;
      font-weight: 900;
      font-size: 35px;
      z-index: 999;
      color: white;
      letter-spacing: 2px;
      @media screen and ( max-width: 800px  ) {
        font-size: 30px !important;
        padding: 0px 10% 0px 10%; } } }
  .ParallaxContainer {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px !important;
    filter: brightness(0.5);
    @media screen and ( max-width: 800px  ) {
      background-attachment: local; } }
  .blogContent {
    background-color: white;
    padding-top: 50px;
    display: flex;
    justify-content: space-evenly;
    @media screen and ( max-width: 800px  ) {
      flex-wrap: wrap; }
    .blogArticles {
      width: 60%;
      margin-left: 10%;
      margin-top: 0%;
      margin-bottom: 5%;
      @media screen and ( max-width: 800px  ) {
        width: 85% !important;
        margin-right: 10%; }
      .date {
        font-family: helvetica;
        font-weight: 200;
        font-size: 18px;
        color: #616161;
        text-align: justify;
        font-style: italic; }
      .content {
        font-family: helvetica;
        font-weight: 200;
        font-size: 18px;
        color: black;
        text-align: justify;
        > p {
          > img {
            width: 100%; } } }
      .commentbox {
        margin-top: 50px; } }
    .articlesRecentsContainer {
      width: 40%;
      margin-left: 5%;
      margin-right: 10%;
      margin-top: 0px;
      @media screen and ( max-width: 800px  ) {
        width: 85% !important; }
      .titrebloc {
        font-size: 20px;
        text-align: left;
        padding: 20px;
        display: flex;
        cursor: default; }
      .recommendedArticle {
        border-top: 1px solid #dedede;
        text-align: left;
        .titre {
          font-size: 16px;
          text-align: left;
          padding: 20px;
          display: flex;
          cursor: pointer;
          color: black;
          &:hover .spantitre {
            margin-left: 10px;
            transition: all 0.3s; }
          .spantitre {
            margin-left: 2px;
            transition: all 0.3s; } } } } } }

//DESKTOP
// @media screen and ( min-width: 940px )

//MOBILE
// @media screen and ( max-width: 940px )
