.navbar-sticky {
  text-align: center;
  position: fixed;
  // top: 0
  z-index: 9999999;
  // filter: drop-shadow(10px 8px 12px rgba(179, 179, 179, 0.178))
  width: 100vw;
  overflow: hidden;
  .livraisonTexteContainer {
    background-color: black;
    color: white;
    padding: 5px;
    .livraisonTexte {
      margin: 0px !important;
      font-weight: 500;
      font-family: Helvetica;
      font-size: 14px;
      .livraisonBold {
        font-weight: bold; } } } }

//MOBILE NAVBAR
@media screen and ( max-width: 1000px ) {
  .navbar.menuOpened {
    height: 410px;
    // height: 311px
    // max-height: 311px
    transition: all 0.3s; }
  .navbar {
    height: 70px;
    transition: all 0.3s;
    overflow: hidden;
    width: 100vw;
    vertical-align: middle;
    z-index: 999;
    background-color: white;
    // text-align: center

    .navbar-burger-menu-button {
      position: absolute;
      padding: 30px;
      right: 0px;
      border: none;
      background: none;
      outline: none;
      transition: all 0.3s;
      cursor: pointer;
      transform: scale(1);
      &:hover {
        color: black;
        transition: all 0.3s;
        transform: scale(1.2); }
      &.menuOpened {
        transition: all 0.3s;
        transform: scale(1.2); } }

    .navbar-logo-container {
      height: 55px;
      display: inline-block;
      margin-left : 14px;
      margin-right : 14px;
      margin-top: 15px;
      .navbar-logo {
        height: 40px;
        display: inline-block;
        vertical-align: middle; } }

    .navbar-menu-shortcutsButtons {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      .navbar-menu-accueilShortcuts {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-transform: none;
        text-decoration: none; }
      .navbar-menu-buttons-container {
        display: flex;
        flex-direction: column;
        > a, div {
          margin-top: 5%; } }
      .navbar-menu {
        line-height: 0px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 16px;
        text-transform: none;
        text-decoration: none;
        color: black;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          transform: scale(1.1);
          transition: all 0.3s; } } }

    .navbar-social-icons-container {
      width: 100px;
      display: inline-flex;
      text-align: center;
      line-height: 60px;
      background-color: white;
      a {
        width: 100%;
        .navbar-social-icon {
          width: 100%;
          margin: auto;
          color: grey;
          transition: all 0.3s;
          &:hover {
            color: black;
            transition: all 0.3s;
            transform: scale(1.4); } } } } } }

//DESKTOP NAVBAR
@media screen and ( min-width: 1000px ) {
  .navbar {
    height: 70px;
    width: 100vw;
    background-color: white;
    transition: all 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .navbar-burger-menu-button {
      visibility: hidden;
      position: absolute; }
    .navbar-logo-container {
      z-index: 999;
      height: 100%;
      min-width: 100px;
      position: abolute;
      text-align: left;
      margin-left : 14px;
      display: flex;
      align-items: center;
      .navbar-logo {
        // left: 0
        height: 40px;
        display: inline-block;
        vertical-align: middle;
 } }        // margin-top: 14px

    .navbar-menu-shortcutsButtons {
      min-width: 750px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .navbar-menu-accueilShortcuts {
        display: flex;
        align-items: center;
        text-transform: none;
        text-decoration: none; }
      .navbar-menu-buttons-container {
        display: flex !important;
        flex-direction: row; }
      .navbar-menu {
        line-height: 40px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 16px;
        text-transform: none;
        text-decoration: none;
        color: black;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          transform: scale(1.1);
          transition: all 0.3s; } } }

    .navbar-social-icons-container {
      min-width: 100px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      line-height: 70px;
      margin-right: 14px;
      .navbar-social-icon {
        padding-left: 7px;
        padding-right: 7px;
        color: grey;
        transition: all 0.3s;
        &:hover {
          color: black;
          transition: all 0.3s;
          transform: scale(1.4); } } } } }

.commander {
  margin: 0px;
  border: 2px solid black !important;
  background-color: black !important;
  transition: all 0.3s !important;
  border-radius: 4px !important;
  font-family: Helvetica !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  color: white !important;
  width: 130px;
  height: 50px;
  letter-spacing: 2px;
  @media screen and ( min-width: 1000px ) {
    margin-right: 10px !important; }
  &:hover {
    cursor: pointer !important;
    border: 2px solid black !important;
    transform: scale(1.1) !important; }
  .startbuttonLine {
    line-height: 100%; } }

.blog {
  border: 2px solid black !important;
  background-color: transparent !important;
  transition: all 0.3s !important;
  border-radius: 4px !important;
  font-family: Helvetica !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  color: black !important;
  width: 130px;
  height: 50px;
  letter-spacing: 2px;
  @media screen and ( min-width: 1000px ) {
    margin-right: 10px !important; }
  &:hover {
    color: white !important;
    background-color: black !important;
    cursor: pointer !important;
    border: 2px solid black !important;
    // padding-left: 40px !important
    // padding-right: -40px !important
    transform: scale(1.1) !important; }
  .blogbuttonLine1 {
    line-height: 15px;
    font-size: 16px !important;
    letter-spacing: 3px;
    font-style: italic; }
  .blogbuttonLine2 {
    line-height: 15px;
    font-weight: 600 !important; } }
