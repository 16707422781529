.flippingCard {
    background-color: transparent;
    min-height: 150px;
    min-width: 360px;
    border-radius: 5px;
    margin: 3%;
    perspective: 1000px;
    &:hover .cardInner {
        transform: rotateX(180deg); }
    @media screen and ( max-width: 750px ) {
        min-width: 160px !important;
        background-color: transparent;
        width: 85vw !important;
        height: 100px !important;
        border-radius: 5px;
        margin: 4%; }
    .cardInner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
        .cardFront, .cardBack {
            display: flex;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            position: absolute;
            .leftContainer {
                display: flex;
                justify-content: space-evenly;
                width: 35%;
                margin: 5%;
                align-content: center;
                .leftImg {
                    height: auto;
                    width: 100%; } }
            .rightContainer {
                width: 65%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                text-align: left;
                padding: 5%;
                padding-left: 0%;
                .titreFlipCard {
                    font-family: futura;
                    font-weight: bold;
                    font-size: 16px;
                    cursor: default;
                    @media screen and ( max-width: 750px ) {
                        font-size: 14px !important; } }
                .texteFlipCard {
                    font-family: futura;
                    font-size: 15px;
                    cursor: default;
                    @media screen and ( max-width: 750px ) {
                        font-size: 13px !important; } } } } } }

.cardFront {
    background-color: white;
    color: black;
    border-radius: 8px; }

/* Style the back side */
.cardBack {
    height: auto !important;
    min-height: 200px;
    background-color: white;
    color: black;
    transform: rotateX(180deg);
    display: block !important;
    border-radius: 8px;
    .titre {
        padding: 6%;
        padding-bottom: 3%;
        font-family: futura;
        font-weight: bold;
        font-size: 15px;
        cursor: default; }
    .backContent {
        padding: 10%;
        padding-top: 3%;
        text-align: left;
        font-family: futura;
        cursor: default; } }

@media screen and ( max-width: 940px ) {
    .cardBack {
        min-height: 100px !important;
        .titre {
            padding: 5% !important;
            text-align: center;
            font-size: 14px !important; }
        .backContent {
            padding: 0% 5% 5% 5%;
            font-size: 12px;
            text-align: justify; }
        .rightContainer {
            .titre {
                font-size: 17px !important; }
            .texte {
                font-size: 16px !important; } } } }
