.livraison_button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    outline: none;
    border: 2px solid black;
    background-color: transparent;
    transition: all 0.3s;
    border-radius: 4px;
    padding: 10px;
    color: black;
    font-family: helvetica;
    font-weight: 700;
    margin-bottom: 5%;
    .left-part {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;
        margin-left: 10px;
        .icon {
            margin-right: 10px; } }
    .right-part {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: left;
        width: 100%;
        .right-up-part {
            font-weight: 500;
            text-align: left;
            .right-up-part-oldPrice {
                font-weight: 300; } }
        .right-down-part {
            font-weight: 300;
            text-align: left; } }

    &:hover {
        background-color: black;
        color: white;
        cursor: pointer; }
    &.chosen {
        background-color: black;
        color: white;
        cursor: pointer; }
    &.disabled {
        border: 2px solid black;
        background-color: transparent;
        color: black;
        cursor: default;
        &:hover {
            border: 2px solid white;
            // background-color: white
            color: white; } } }
