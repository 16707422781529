.question1_container {
    // padding-top: 10vh
    // padding-bottom: 5vh
    display: flex;
    justify-content: center;
    // align-items: center
    width: 100vw;
    height: 100vh;
    flex-wrap: wrap;
    overflow-y: scroll !important;
    background: linear-gradient(90deg, #F08F7C 100%, #F08F7C 100%);
    .cadeauInfosBloc {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 50%;
        &.blocBottom {
            justify-content: flex-start;
            width: 90%;
            margin-top: 0px; }
        .titreCadeau1Question {
            color: white; }
        .question1_input {
            border-color: #cccccc;
            padding: 10px;
            font-size: 15px;
            height: 20px;
            width: 45%;
            border-width: 0px;
            border-radius: 10px;
            border-style: solid;
            border: 3px rgb(210, 255, 200) solid;
            box-shadow: 4px 1px 30px 0px rgba(42, 42, 42, 0.17);
            text-shadow: 0px 0px 0px rgba(42, 42, 42, 0.75);
            text-decoration: none;
            outline: none;
            margin: 15px 0 15px 0;
            font-family: helvetica;
            transition: all 0.3s;
            &.textareagift {
                min-height: 60px !important; }
            &.invalid {
                border: 4px #F50208 solid; } }
        .cadeauButton {
            margin-top: 80px;
            max-width: 150px !important; } } }
@media screen and (max-width: 750px) {
    .question1_container {
        height: 100vh;
        max-height: calc(var(--vhmobileheight, 1vh) * 100) !important;
        .cadeauInfosBloc {
            margin-top: 80px;
            width: 100%;
            height: fit-content;
            &.blocBottom {
                height: fit-content;
                margin-top: 80px; }
            .titreCadeau1Question {
                margin: 0%; }
            .question1_input {
                width: 80%;
                margin: 3% 0 3% 0; }
            .question1_slider {
                width: 88%;
                margin: 3% 0 3% 0; }
            .question1_label_slider {
                margin-top: -53px !important; } } } }
