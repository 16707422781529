.notFoundBg {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #F08F7C;
  clip-path: polygon(33% 88%, 69% 93%, 100% 91%, 100% 100%, 0 100%, 0 92%); }
.notFoundContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: white;
  .notFoundContent {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25%;
    .notFoundLogo {
      width: 100%;
      height: 100%; }
    .notFoundText {
      font-family: futura;
      color: black;
      font-size: 35px;
      &.subText {
        font-size: 24px; } } } }

//DESKTOP
// @media screen and ( min-width: 940px )

//MOBILE
// @media screen and ( max-width: 940px )
