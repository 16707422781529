.lineRecap {
    font-family: Helvetica;
    margin: 10px;
    margin-top: 0px;
    .titre {
        cursor: default;
        display: flex;
        justify-content: left;
        font-size: 14px;
        font-weight: 500;
        @media screen and (max-width: 750px) {
            font-size: 13px; } }
    .valueContainer {
        padding-top: 5px;
        cursor: pointer;
        display: flex;
        justify-content: left;
        border: 1px solid black;
        border-radius: 8px;
        overflow: hidden;
        @media screen and (max-width: 750px) {
            font-size: 13px; }
        &.arenseigner {
            font-weight: 500;
            border: 2px solid #F08F7C;
            background-color: #F08F7C;
            color: white; }
        &.disabled {
            cursor: default !important;
            font-weight: 500;
            color: black !important; }
        .modif {
            margin-left: 5px;
            margin-right: 10px; }
        .valid {
            margin-left: 5px;
            margin-right: 5px;
            color: green; }
        .valeur {
            font-weight: 500;
            @media screen and (max-width: 750px) {
                font-size: 12px; } } } }
