.accueilContainer {
  width: 100vw;
  overflow-x: hidden;
  > section {
    height: 90vh;
    overflow: hidden; }
  .sectionTitre {
    font-family: helvetica;
    font-weight: 800;
    font-size: 35px;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
    transform: rotate(-3deg);
    @media screen and (max-width: 750px) {
      font-size: 23px !important;
      margin-top: 10%;
      margin-bottom: 10%; } }
  .accueilSectionHome {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
    // margin-bottom: -20vh
    // background: linear-gradient(145deg, #F08F7C 0%, #ff694f 100%)
    // background: rgb(248, 248, 248)
    // backdrop-filter: blur(5px)    // clip-path: polygon(63% 4%, 100% 0, 100% 100%, 0 100%, 0 0, 22% 4%)
 }    // overflow: hidden !important
  .accueilSectionConcept {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #F08F7C;
    clip-path: polygon(35% 3%, 70% 0%, 100% 2%, 100% 100%, 69% 97%, 30% 100%, 0 97%, 0 0); }
  .accueilSectionGammes {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @media screen and (max-width: 750px) {
      height: 110vh; } }
  .accueilSectionPourquoi {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #F08F7C;
    clip-path: polygon(35% 3%, 70% 0%, 100% 2%, 100% 100%, 69% 97%, 30% 100%, 0 97%, 0 0);
    @media screen and (max-width: 750px) {
      height: 125vh; } }
  .accueilSectionAvis {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; } }
//AGE POPUP
.accueil-rodal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 7vh;
  .accueil-rodal-titre {
    padding: 0 10% 0 10%;
    line-height: 30px;
    text-align: center;
    > div {
      margin: 0; } }
  .accueil-rodal-logo {
    min-height: 150px;
    min-width: 150px;
    > img {
      width: 100%;
      height: 100%; } }
  .accueil-rodal-button {
    border: 0px;
    width: 48%;
    height: 50px;
    font-family: helvetica;
    text-transform: uppercase;
    transition: background-color 0.3s;
    color: black;
    vertical-align: middle;
    transition: all 0.3s;
    background-color: #F08F7C;
    color: white !important;
    &:hover {
      background-color: rgb(36, 100, 151);
      color: white !important;
      transition: all 0.3s; }
    &:focus {
      outline: none; } } }

.a_button {
  display: flex;
  align-items: center;
  outline: none;
  border: 2px solid black;
  background-color: black;
  transition: all 0.3s;
  border-radius: 4px;
  padding: 9px;
  color: white;
  font-family: helvetica;
  font-weight: 700;
  margin: 5%;
  align-items: top;
  &:hover {
    cursor: pointer;
    border: 2px solid black;
    transform: scale(1.1); }
  @media screen and ( max-width: 750px ) {
    width: 55% !important; }
  &:active {
    background-color: whiten(#ea332b, 10); } }

.slick-dots li.slick-active button:before {
  color: white; }

.slick-dots li.slick-active button:before {
  color: white; }

.rodal-mask {
  position: fixed !important;
  margin: 0px !important; }

.rodal-dialog {
  width: 50% !important;
  max-width: 530px;
  min-width: 430px;
  height: fit-content !important {
    // height: 75% !important
 }    // min-height: 630px
  margin-top: 90px !important;
  margin-bottom: 10%;
  overflow: hidden {
    // max-width: 400px !important
 }    // max-height: 500px !important
  @media screen and (max-width: 750px) {
    margin-top: 90px !important;
    width: 88% !important;
    height: fit-content !important;
    min-width: 200px !important;
    overflow: hidden; } }

.CookieConsent {
  background-color: rgba(0,0,0,1) !important;
  font-family: futura !important;
  font-size: 18px !important;
  display: flex !important;
  flex-direction: column-reverse !important;
  justify-content: center !important;
  align-items: center !important;
  padding-top: 20px;
  padding-bottom: 20px;
  @media screen and (max-width: 750px) {
    font-size: 14px !important; }
  > div {
    margin: 0px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 80% !important;
    max-height: 80px !important;
    @media screen and (max-width: 750px) {
      max-height: 120px !important; }
    #rcc-confirm-button {
      width: 80% !important;
      max-width: 250px !important;
      height: 100% !important;
      background: #fff !important;
      padding: 15px !important;
      border-radius: 5px !important;
      font-family: Helvetica !important; } } }
