.slick-slide {
    outline: none;
    user-select: none;
    >div {
        outline: none;
        user-select: none;
        >div {
            outline: none;
            user-select: none; } } }
.slick-next {
    right: 170px;
    height: 40px;
    @media screen and ( max-width: 750px ) {
        right: 30px; } }
.slick-prev {
    left: 170px;
    height: 40px;
    z-index: 999;
    @media screen and ( max-width: 750px ) {
        left: 30px; } }
.slick-prev:before {
    // content: "<"
    font-size: 30px; }
.slick-next:before {
    // content: ">"
    font-size: 30px; }
.accueilSection2slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80%;
    max-width: 70vw;
    @media screen and ( max-width: 750px ) {
        max-width: 70vw; }
    .imgcontainer {
        max-height: 300px;
        // width: 100vw
        height: 20%;
        @media screen and ( max-width: 750px ) {
            max-height: 180px; }
        .accueilSection2Img1 {
            // width: auto
            max-width: 400px;
            max-height: 300px;
            align-self: center;
            display: inline;
            @media screen and ( max-width: 750px ) {
                max-height: 180px; } } } }

.accueilSection2Nb {
    color: white;
    margin: 10px;
    @media screen and ( max-width: 750px ) {
        margin: 0px; } }

.accueilSection2Txt {
    color: white;
    display: flex;
    justify-content: center;
    > h3 {
        font-size: 22px;
        width: 80vw;
        min-width: 200px;
        @media screen and ( max-width: 750px ) {
            font-size: 16px; } } }
