html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: helvetica;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: helvetica;
  font-weight: 100;
}

@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");

h2 {
  font-family: futura_ltbold;
  font-size: 34px;
  /* text-transform: uppercase; */
}

h3 {
  font-family: helvetica;
  font-weight: 500;
  font-size: 28px;
  /* text-transform: uppercase; */
}
h4 {
  font-family: helvetica;
  font-variation-settings: "wght" 600;
  font-size: 21px;
  /* text-transform: uppercase; */
}
h5 {
  font-family: helvetica;
  font-size: 16px;
  /* text-transform: uppercase; */
}
h6 {
  font-family: helvetica;
  font-size: 14px;
  /* text-transform: uppercase; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

Link {
  text-transform: none;
  text-decoration: none;
}

/* POLICES */

@font-face {
  font-family: "futura_ltbold";
  src: url("./fonts/futuralt-bold-webfont.woff2") format("woff2"),
    url("./fonts/futuralt-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "futura_ltbook";
  src: url("./fonts/futuralt-book-webfont.woff2") format("woff2"),
    url("./fonts/futuralt-book-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "futura_ltlight";
  src: url("./fonts/futuralt-light-webfont.woff2") format("woff2"),
    url("./fonts/futuralt-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "modernist_bold";
  src: url("./fonts/sk-modernist-bold-webfont.woff2") format("woff2"),
    url("./fonts/sk-modernist-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "modernist_regular";
  src: url("./fonts/sk-modernist-regular-webfont.woff2") format("woff2"),
    url("./fonts/sk-modernist-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "raleway";
  src: url("./fonts/raleway-variablefont_wght-webfont.woff2") format("woff2"),
    url("./fonts/raleway-variablefont_wght-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* ANIMATE.CSS */
:root {
  --animate-duration: 1s;
  --animate-delay: 0.2s !important;
  --animate-repeat: 1;
}

.loadingPanel {
  position: fixed;
  background-color: #f08f7c;
  width: 100vw;
  height: 100vh;
  z-index: -99999999999999 !important;
  /* overflow: scroll; */
  pointer-events: none !important;
  /* transform: rotate(25deg); */
}
