
.footerContainer {
    background-color: #181818;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    >div {
        min-width: 50px;
        height: 10px;
        color: white;
        font-family: Helvetica;
        font-size: 20px;
        font-weight: bold;
        @media screen and ( max-width: 800px  ) {
            font-size: 14px;
            padding-left: 5%;
            padding-right: 5%; } }
    .calltoactionBloc {
        height: 40px;
        background-color: transparent;
        display: flex;
        align-items: center; }
    .newsletterBloc {
        display: flex;
        align-items: center;
        height: 50px;
        .buttonNewsletter {
            border: 2px solid white;
            background-color: white;
            transition: all 0.3s;
            border-radius: 4px;
            font-family: Helvetica;
            font-weight: 700;
            font-size: 12px;
            color: black;
            letter-spacing: 2px;
            width: 130px;
            height: 50px;
            &:hover {
                color: white;
                background-color: #181818;
                cursor: pointer;
                transform: scale(1.1); } } }
    .linksBloc {
        width: 60%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        @media screen and ( max-width: 1000px  ) {
            width: 95%; }
        @media screen and ( max-width: 800px  ) {
            width: 98%; }
        > a {
            color: white;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 14px;
            filter: brightness(0.6);
            transition: all 0.3s;
            width: 120px;
            &:hover {
                filter: brightness(1);
                transition: all 0.3s; }
            @media screen and ( max-width: 1000px  ) {
                font-size: 12px; }
            @media screen and ( max-width: 800px  ) {
                font-size: 10px;
                min-width: 50px; } } }

    .legalBloc {
        width: 60%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        > span {
            color: white;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 11px;
            filter: brightness(0.4);
            cursor: default; }
        @media screen and ( max-width: 800px  ) {
            width: 90%; } }
    .socialsBloc {
        width: 20%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        > a {
            color: white;
            transition: all 0.3s;
            &:hover {
                transform: scale(1.2);
                transition: all 0.3s; } }
        @media screen and ( max-width: 800px  ) {
            width: 50%; } }

    .paymentBloc {
        width: 20%;
        height: 43px;
        min-width: 200px;
        .paymentImg {
            width: 100%; } }

    .warningBloc {
        font-size: 11px;
        cursor: default;
        user-select: none; } }
